<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">
<div class="container mb80">
    <div class="page-timeline">


      <div class="vtimeline-point">
          <div class="vtimeline-icon">
              <i class="fa fa-image"></i>
          </div>
          <div class="vtimeline-block">
              <span class="vtimeline-date"> May, 2021</span><div class="vtimeline-content">

                  <div id="project7" class="carousel slide mb20" data-bs-ride="carousel">
                      <div class="carousel-indicators">
                        <button type="button" data-bs-target="#project7" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#project7" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#project7" data-bs-slide-to="2" aria-label="Slide 3"></button>
                      </div>
                      <div class="carousel-inner">
                        <div class="carousel-item active">
                          <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624760918/37_kvmhqn.png" class="d-block w-100" alt="...">
                        </div>
                        <div class="carousel-item">
                          <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624760918/38_ww5l14.png" class="d-block w-100" alt="...">
                        </div>
                        <div class="carousel-item">
                          <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624760918/39_wlfi4z.png" class="d-block w-100" alt="...">
                        </div>
                      </div>
                      <button class="carousel-control-prev" type="button" data-bs-target="#project7" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true" style="color:black"></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button class="carousel-control-next" type="button" data-bs-target="#project7" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>
                  <h3>9byIWC</h3>
                  <ul class="post-meta list-inline">
                      <li class="list-inline-item">
                          <i class="fas fa-mouse"></i><span class="repo"><a href="https://9byiwc.com/" class="linkCol"> Demo</a></span>
                      </li>
                      <li class="list-inline-item">
                          <i class="fa fa-tags trans"></i> Team Member
                      </li><br>
                      <li class="list-inline-item">
                        <i class="fas fa-map-marker-alt"></i> London, UK
                      </li>
                      <br>
                      <li class="list-inline-item">
                        <i class="fas fa-code"></i> Wordpress
                      </li>

                  </ul>
                  <p>
                    The website is dedicated to the 9byIWC team whereby fresh watch related content fused with passion points is regularly posted.
                      My contributions to the website include refactoring front-end code and integrating additional libraries such as newsletters, etc.
                  </p><br>
              </div>
          </div>
      </div>




                  <div class="vtimeline-point">
                      <div class="vtimeline-icon">
                          <i class="fa fa-image"></i>
                      </div>
                      <div class="vtimeline-block">
                          <span class="vtimeline-date"> May, 2021</span><div class="vtimeline-content">

                              <div id="project6" class="carousel slide mb20" data-bs-ride="carousel">
                                  <div class="carousel-indicators">
                                    <button type="button" data-bs-target="#project6" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#project6" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#project6" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    <button type="button" data-bs-target="#project6" data-bs-slide-to="3" aria-label="Slide 3"></button>
                                    <button type="button" data-bs-target="#project6" data-bs-slide-to="4" aria-label="Slide 3"></button>
                                    <button type="button" data-bs-target="#project6" data-bs-slide-to="5" aria-label="Slide 3"></button>
                                  </div>
                                  <div class="carousel-inner">
                                    <div class="carousel-item active">
                                      <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624748676/31_fntnpx.png" class="d-block w-100" alt="...">
                                    </div>
                                    <div class="carousel-item">
                                      <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624748676/32_qy79fa.png" class="d-block w-100" alt="...">
                                    </div>
                                    <div class="carousel-item">
                                      <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624748676/33_dvberv.png" class="d-block w-100" alt="...">
                                    </div>
                                    <div class="carousel-item">
                                      <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624748676/34_ozmph7.png" class="d-block w-100" alt="...">
                                    </div>
                                    <div class="carousel-item">
                                      <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624748676/35_trcpqu.png" class="d-block w-100" alt="...">
                                    </div>
                                    <div class="carousel-item">
                                      <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624748676/36_uln3qe.png" class="d-block w-100" alt="...">
                                    </div>
                                  </div>
                                  <button class="carousel-control-prev" type="button" data-bs-target="#project6" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true" style="color:black"></span>
                                    <span class="visually-hidden">Previous</span>
                                  </button>
                                  <button class="carousel-control-next" type="button" data-bs-target="#project6" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                  </button>
                                </div>
                              <h3>Visitt.Us</h3>
                              <ul class="post-meta list-inline">
                                  <li class="list-inline-item">
                                      <i class="fab fa-github trans"></i><span class="repo"><a href="https://github.com/aditya-5/iamyc" class="linkCol"> Repository</a></span>
                                  </li>
                                  <li class="list-inline-item">
                                      <i class="fa fa-tags trans"></i> Hackathon, Society
                                  </li><br>
                                  <li class="list-inline-item">
                                    <i class="fas fa-map-marker-alt"></i> Hack Girl Summer 2.0 : Major League Hacking (MLH)
                                  </li>
                                  <br>
                                  <li class="list-inline-item">
                                    <i class="fas fa-code"></i> HTML, CSS, nodeJS, mongoDB, BootStrap, JS+jQuery, scanThng, CockroachDB, PostgreSQL, Linode, Paypal Checkout v2
                                  </li>

                              </ul>
                              <p>
                                Visitt.Us is a comprehensive digital membership service that provides access to exclusive offers, and delightful experiences across partner restaurants and hotels.
                                The primary purpose of the website is to allow the partners (for instance - the servers in the restaurants) to verify a member's eligibility by scanning their membership QR codes on the fly.
                                Additionally, it allows people to purchase the Visitt.us membership directly from the website instead of going through a tiresome process of making a manual bank transfer and then communicating with us for the activation.
                              </p><br>
                          </div>
                      </div>
                  </div>


                  <div class="vtimeline-point">
                      <div class="vtimeline-icon">
                          <i class="fa fa-image"></i>
                      </div>
                      <div class="vtimeline-block">
                          <span class="vtimeline-date"> May, 2021</span><div class="vtimeline-content">

                              <div id="project9" class="carousel slide mb20" data-bs-ride="carousel">
                                  <div class="carousel-indicators">
                                    <button type="button" data-bs-target="#project9" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#project9" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#project9" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    <button type="button" data-bs-target="#project9" data-bs-slide-to="3" aria-label="Slide 3"></button>
                                    <button type="button" data-bs-target="#project9" data-bs-slide-to="4" aria-label="Slide 3"></button>
                                  </div>
                                  <div class="carousel-inner">
                                    <div class="carousel-item active">
                                      <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624793650/43_p05vfl.png" class="d-block w-100" alt="...">
                                    </div>
                                    <div class="carousel-item">
                                      <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624793872/45_bsopgd.png" class="d-block w-100" alt="...">
                                    </div>
                                    <div class="carousel-item">
                                      <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624793871/44_oqk4sm.png" class="d-block w-100" alt="...">
                                    </div>
                                    <div class="carousel-item">
                                      <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624793872/46_ku9lib.png" class="d-block w-100" alt="...">
                                    </div>
                                    <div class="carousel-item">
                                      <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624793872/47_e1qcoe.png" class="d-block w-100" alt="...">
                                    </div>
                                  </div>
                                  <button class="carousel-control-prev" type="button" data-bs-target="#project9" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true" style="color:black"></span>
                                    <span class="visually-hidden">Previous</span>
                                  </button>
                                  <button class="carousel-control-next" type="button" data-bs-target="#project9" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                  </button>
                                </div>
                              <h3>MazeSolver</h3>
                              <ul class="post-meta list-inline">
                                  <li class="list-inline-item">
                                      <i class="fa fa-tags trans"></i> Coursework
                                  </li><br>
                                  <li class="list-inline-item">
                                    <i class="fas fa-map-marker-alt"></i> University of Manchester
                                  </li>
                                  <br>
                                  <li class="list-inline-item">
                                    <i class="fas fa-code"></i> Java, JavaFX
                                  </li>

                              </ul>
                              <p>
                                An automatic Maze Solver application builting using JavaFX that can load existing maps and routes. It can save routes and maps in both textual and serialized binary encoding.
                              </p><br>
                          </div>
                      </div>
                  </div>





            <div class="vtimeline-point">
                <div class="vtimeline-icon">
                    <i class="fa fa-image"></i>
                </div>
                <div class="vtimeline-block">
                    <span class="vtimeline-date"> April, 2021</span><div class="vtimeline-content">

                        <div id="project5" class="carousel slide mb20" data-bs-ride="carousel">
                            <div class="carousel-indicators">
                              <button type="button" data-bs-target="#project5" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                              <button type="button" data-bs-target="#project5" data-bs-slide-to="1" aria-label="Slide 2"></button>
                              <button type="button" data-bs-target="#project5" data-bs-slide-to="2" aria-label="Slide 3"></button>
                              <button type="button" data-bs-target="#project5" data-bs-slide-to="3" aria-label="Slide 3"></button>
                              <button type="button" data-bs-target="#project5" data-bs-slide-to="4" aria-label="Slide 3"></button>
                              <button type="button" data-bs-target="#project5" data-bs-slide-to="5" aria-label="Slide 3"></button>
                            </div>
                            <div class="carousel-inner">
                              <div class="carousel-item active">
                                <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624747774/25_lpwhtv.png" class="d-block w-100" alt="...">
                              </div>
                              <div class="carousel-item">
                                <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624747744/26_tjjima.png" class="d-block w-100" alt="...">
                              </div>
                              <div class="carousel-item">
                                <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624747744/27_u59ucd.png" class="d-block w-100" alt="...">
                              </div>
                              <div class="carousel-item">
                                <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624747745/28_fkm1ez.png" class="d-block w-100" alt="...">
                              </div>
                              <div class="carousel-item">
                                <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624747744/29_ynxzxh.png" class="d-block w-100" alt="...">
                              </div>
                              <div class="carousel-item">
                                <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624747745/30_s7twjd.png" class="d-block w-100" alt="...">
                              </div>
                            </div>
                            <button class="carousel-control-prev" type="button" data-bs-target="#project5" data-bs-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true" style="color:black"></span>
                              <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#project5" data-bs-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="visually-hidden">Next</span>
                            </button>
                          </div>
                        <h3>Foodents</h3>
                        <ul class="post-meta list-inline">
                            <li class="list-inline-item">
                                <i class="fab fa-github trans"></i><span class="repo"><a href="https://github.com/aditya-5/Foodents" class="linkCol"> Repository</a></span>
                            </li>
                            <li class="list-inline-item">
                                <i class="fa fa-tags trans"></i> Year Project
                            </li><br>
                            <li class="list-inline-item">
                              <i class="fas fa-map-marker-alt"></i> University of Manchester
                            </li>
                            <br>
                            <li class="list-inline-item">
                              <i class="fas fa-code"></i> HTML, CSS, PHP, jQuery, JS, Boostrap, FontAwesome, animateCSS
                            </li>

                        </ul>
                        <p>
                          A recipe book website which allows the users to search recipes based on the specific set of ingredients
                          that that have their kitchen. It is also a place for the users to add their custom recipes and
                          socialise with other users.
                        </p><br>
                    </div>
                </div>
            </div>



            <div class="vtimeline-point">
                <div class="vtimeline-icon">
                    <i class="fa fa-image"></i>
                </div>
                <div class="vtimeline-block">
                    <span class="vtimeline-date"> April, 2021</span><div class="vtimeline-content">

                        <div id="project8" class="carousel slide mb20" data-bs-ride="carousel">
                            <div class="carousel-indicators">
                              <button type="button" data-bs-target="#project8" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                              <button type="button" data-bs-target="#project8" data-bs-slide-to="1" aria-label="Slide 2"></button>
                              <button type="button" data-bs-target="#project8" data-bs-slide-to="2" aria-label="Slide 3"></button>
                              <button type="button" data-bs-target="#project5" data-bs-slide-to="3" aria-label="Slide 3"></button>
                              <button type="button" data-bs-target="#project5" data-bs-slide-to="4" aria-label="Slide 3"></button>
                              <button type="button" data-bs-target="#project5" data-bs-slide-to="5" aria-label="Slide 3"></button>
                            </div>
                            <div class="carousel-inner">
                              <div class="carousel-item active">
                                <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624792795/40_ojwzcd.png" class="d-block w-100" alt="...">
                              </div>
                              <div class="carousel-item">
                                <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624792795/41_ytjkb3.png" class="d-block w-100" alt="...">
                              </div>
                              <div class="carousel-item">
                                <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624792795/42_faji1i.png" class="d-block w-100" alt="...">
                              </div>
                            </div>
                            <button class="carousel-control-prev" type="button" data-bs-target="#project8" data-bs-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true" style="color:black"></span>
                              <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#project8" data-bs-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="visually-hidden">Next</span>
                            </button>
                          </div>
                        <h3>Java : Command Line Chess Game </h3>
                        <ul class="post-meta list-inline">
                            <li class="list-inline-item">
                                <i class="fa fa-tags trans"></i> Coursework
                            </li><br>
                            <li class="list-inline-item">
                              <i class="fas fa-map-marker-alt"></i> University of Manchester
                            </li>
                            <br>
                            <li class="list-inline-item">
                              <i class="fas fa-code"></i> Java
                            </li>

                        </ul>
                        <p>
                          A command-line chess game built using Java.
                        </p><br>
                    </div>
                </div>
            </div>




      <div class="vtimeline-point">
          <div class="vtimeline-icon">
              <i class="fa fa-image"></i>
          </div>
          <div class="vtimeline-block">
              <span class="vtimeline-date"> February-April, 2021</span><div class="vtimeline-content">

                  <div id="project4" class="carousel slide mb20" data-bs-ride="carousel">
                      <div class="carousel-indicators">
                        <button type="button" data-bs-target="#project4" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#project4" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#project4" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#project4" data-bs-slide-to="3" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#project4" data-bs-slide-to="4" aria-label="Slide 3"></button>
                      </div>
                      <div class="carousel-inner">
                        <div class="carousel-item active">
                          <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734436/20_lxrmwq.png" class="d-block w-100" alt="...">
                        </div>
                        <div class="carousel-item">
                          <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734436/21_c5u2pv.png" class="d-block w-100" alt="...">
                        </div>
                        <div class="carousel-item">
                          <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734437/22_ytjb8w.png" class="d-block w-100" alt="...">
                        </div>
                        <div class="carousel-item">
                          <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734437/23_okugbq.png" class="d-block w-100" alt="...">
                        </div>
                        <div class="carousel-item">
                          <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734438/24_vkladq.png" class="d-block w-100" alt="...">
                        </div>
                      </div>
                      <button class="carousel-control-prev" type="button" data-bs-target="#project4" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true" style="color:black"></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button class="carousel-control-next" type="button" data-bs-target="#project4" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>
                  <h3>RecipeBook</h3>
                  <ul class="post-meta list-inline">
                      <li class="list-inline-item">
                          <i class="fab fa-github trans"></i><span class="repo"><a href="https://github.com/aditya-5/angular-shop" class="linkCol"> Repository</a></span>
                      </li>
                      <li class="list-inline-item">
                          <i class="fa fa-tags trans"></i> Online Course
                      </li><br>
                      <li class="list-inline-item">
                        <i class="fas fa-map-marker-alt"></i> Angular Guide by Maximilian Schwarzmüller
                      </li>
                      <br>
                      <li class="list-inline-item">
                        <i class="fas fa-code"></i> Angular, BootStrap, Firebase
                      </li>

                  </ul>
                  <p>
                    A fully functional recipe book and shopping list system integrated into one single-page app. The app also consists of a
                    working login system built using Firebase. This includes auto-login and auto-logout.

                  </p><br>
              </div>
          </div>
      </div>




          <div class="vtimeline-point">
              <div class="vtimeline-icon">
                  <i class="fa fa-image"></i>
              </div>
              <div class="vtimeline-block">
                  <span class="vtimeline-date">19-21 March, 2021</span><div class="vtimeline-content">

                      <div id="project1" class="carousel slide mb20" data-bs-ride="carousel">
                          <div class="carousel-indicators">
                            <button type="button" data-bs-target="#project1" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#project1" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#project1" data-bs-slide-to="2" aria-label="Slide 3"></button>
                          </div>
                          <div class="carousel-inner">
                            <div class="carousel-item active">
                              <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734285/1_jsyepf.png" class="d-block w-100" alt="...">
                            </div>
                            <div class="carousel-item">
                              <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734334/2_s7mqqe.png" class="d-block w-100" alt="...">
                            </div>
                            <div class="carousel-item">
                              <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734339/3_co54gd.png" class="d-block w-100" alt="...">
                            </div>
                          </div>
                          <button class="carousel-control-prev" type="button" data-bs-target="#project1" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true" style="color:black"></span>
                            <span class="visually-hidden">Previous</span>
                          </button>
                          <button class="carousel-control-next" type="button" data-bs-target="#project1" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                          </button>
                        </div>
                      <h3>StreetSpots</h3>
                      <ul class="post-meta list-inline">
                          <li class="list-inline-item">
                              <i class="fab fa-github trans"></i><span class="repo"><a href="https://github.com/aditya-5/StreetSpots" class="linkCol"> Repository</a></span>
                          </li>
                          <li class="list-inline-item">
                              <i class="fa fa-tags trans"></i> Hackathon
                          </li>
                          <li class="list-inline-item">
                            <i class="fas fa-trophy winner"></i>
                          </li>
                          <br>
                          <li class="list-inline-item">
                            <i class="fas fa-map-marker-alt"></i> HackMerced - University of California, Merced
                          </li>


                          <br>
                          <li class="list-inline-item">
                            <i class="fas fa-code"></i> PHP, HTML, CSS, BootStrap, JavaScript, jQuery
                          </li>



                      </ul>
                      <p>
                        StreetSpots aims at promoting social good by supporting the local street vendors around you.
                        It allows you to add information about a particular street vendor you may have spotted. Other users
                        can then view all the street vendors around them on an interactive map built using Google Maps API.
                      </p><br>
                  </div>
              </div>
          </div>





                    <div class="vtimeline-point">
                        <div class="vtimeline-icon">
                            <i class="fa fa-image"></i>
                        </div>
                        <div class="vtimeline-block">
                            <span class="vtimeline-date">2-5 March, 2021</span><div class="vtimeline-content">

                                <div id="project2" class="carousel slide mb20" data-bs-ride="carousel">
                                    <div class="carousel-indicators">
                                      <button type="button" data-bs-target="#project2" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                      <button type="button" data-bs-target="#project2" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                      <button type="button" data-bs-target="#project2" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                      <button type="button" data-bs-target="#project2" data-bs-slide-to="3" aria-label="Slide 3"></button>
                                      <button type="button" data-bs-target="#project2" data-bs-slide-to="4" aria-label="Slide 3"></button>
                                      <button type="button" data-bs-target="#project2" data-bs-slide-to="5" aria-label="Slide 3"></button>
                                      <button type="button" data-bs-target="#project2" data-bs-slide-to="6" aria-label="Slide 3"></button>
                                    </div>
                                    <div class="carousel-inner">
                                      <div class="carousel-item active">
                                        <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734380/4_zo3hoc.png" class="d-block w-100" alt="...">
                                      </div>
                                      <div class="carousel-item">
                                        <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734402/5_tv6yqr.png" class="d-block w-100" alt="...">
                                      </div>
                                      <div class="carousel-item">
                                        <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734426/6_abgezc.png" class="d-block w-100" alt="...">
                                      </div>
                                      <div class="carousel-item">
                                        <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734428/7_mtwr1t.png" class="d-block w-100" alt="...">
                                      </div>
                                      <div class="carousel-item">
                                        <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734442/8_ijvpvp.png" class="d-block w-100" alt="...">
                                      </div>
                                      <div class="carousel-item">
                                        <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734430/9_ateqjg.png" class="d-block w-100" alt="...">
                                      </div>
                                      <div class="carousel-item">
                                        <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734429/10_ucxmoe.png" class="d-block w-100" alt="...">
                                      </div>
                                    </div>
                                    <button class="carousel-control-prev" type="button" data-bs-target="#project2" data-bs-slide="prev">
                                      <span class="carousel-control-prev-icon" aria-hidden="true" style="color:black"></span>
                                      <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#project2" data-bs-slide="next">
                                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                      <span class="visually-hidden">Next</span>
                                    </button>
                                  </div>
                                <h3>UpProcess</h3>
                                <ul class="post-meta list-inline">
                                    <li class="list-inline-item">
                                        <i class="fab fa-github trans"></i><span class="repo"><a href="https://github.com/aditya-5/UpProcess" class="linkCol"> Repository</a></span>
                                    </li>
                                    <li class="list-inline-item">
                                        <i class="fa fa-tags trans"></i> Hackathon
                                    </li><br>
                                    <li class="list-inline-item">
                                      <i class="fas fa-map-marker-alt"></i> Makeathon 3077 - Thapar University, India
                                    </li>
                                    <br>
                                    <li class="list-inline-item">
                                      <i class="fas fa-code"></i> PHP, HTML, CSS, BootStrap, JavaScript, jQuery
                                    </li>

                                </ul>
                                <p>
                                  The app uProcess is a solution with the target audience as commercial organizations.
                                  At at the most basic level, the app allows the HR Department or manager in general to
                                  assign work to the company's employees and keep a tally of their attendance. It can also
                                  be thought of a simple attendance and a to-do list app merged into one with some added functionality.
                                </p><br>
                            </div>
                        </div>
                    </div>


                    <div class="vtimeline-point">
                        <div class="vtimeline-icon">
                            <i class="fa fa-image"></i>
                        </div>
                        <div class="vtimeline-block">
                            <span class="vtimeline-date">February, 2021</span><div class="vtimeline-content">

                                <div id="project12" class="carousel slide mb20" data-bs-ride="carousel">
                                    <div class="carousel-indicators">
                                      <button type="button" data-bs-target="#project12" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                      <button type="button" data-bs-target="#project12" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    </div>
                                    <div class="carousel-inner">
                                      <div class="carousel-item active">
                                        <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624795948/53_l144w1.png" class="d-block w-100" alt="...">
                                      </div>
                                      <div class="carousel-item">
                                        <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624795949/54_q5utef.png" class="d-block w-100" alt="...">
                                      </div>
                                    </div>
                                    <button class="carousel-control-prev" type="button" data-bs-target="#project12" data-bs-slide="prev">
                                      <span class="carousel-control-prev-icon" aria-hidden="true" style="color:black"></span>
                                      <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#project12" data-bs-slide="next">
                                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                      <span class="visually-hidden">Next</span>
                                    </button>
                                  </div>
                                <h3>Contact Form</h3>
                                <ul class="post-meta list-inline">
                                    <li class="list-inline-item">
                                        <i class="fab fa-github trans"></i><span class="repo"><a href="https://github.com/aditya-5/contact-form" class="linkCol"> Repository</a></span>
                                    </li>
                                    <li class="list-inline-item">
                                        <i class="fa fa-tags trans"></i> Side Project
                                    </li><br>
                                    <li class="list-inline-item">
                                      <i class="fas fa-map-marker-alt"></i> Manchester, UK
                                    </li>
                                    <br>
                                    <li class="list-inline-item">
                                      <i class="fas fa-code"></i> HTML, CSS, BootStrap, JavaScript, jQuery
                                    </li>

                                </ul>
                                <p>
                                  A simple front-end contact form with dynamic elements from BootStrap Library.
                                </p><br>
                            </div>
                        </div>
                    </div>




                    <div class="vtimeline-point">
                        <div class="vtimeline-icon">
                            <i class="fa fa-image"></i>
                        </div>
                        <div class="vtimeline-block">
                            <span class="vtimeline-date"> 20-29 November, 2020</span><div class="vtimeline-content">

                                <div id="project3" class="carousel slide mb20" data-bs-ride="carousel">
                                    <div class="carousel-indicators">
                                      <button type="button" data-bs-target="#project3" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                      <button type="button" data-bs-target="#project3" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                      <button type="button" data-bs-target="#project3" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                      <button type="button" data-bs-target="#project3" data-bs-slide-to="3" aria-label="Slide 3"></button>
                                      <button type="button" data-bs-target="#project3" data-bs-slide-to="4" aria-label="Slide 3"></button>
                                      <button type="button" data-bs-target="#project3" data-bs-slide-to="5" aria-label="Slide 3"></button>
                                      <button type="button" data-bs-target="#project3" data-bs-slide-to="6" aria-label="Slide 3"></button>
                                      <button type="button" data-bs-target="#project3" data-bs-slide-to="7" aria-label="Slide 3"></button>
                                      <button type="button" data-bs-target="#project3" data-bs-slide-to="8" aria-label="Slide 3"></button>
                                    </div>
                                    <div class="carousel-inner">
                                      <div class="carousel-item active">
                                        <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734458/11_a4t4mc.png" class="d-block w-100" alt="...">
                                      </div>
                                      <div class="carousel-item">
                                        <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734430/12_mhcqny.png" class="d-block w-100" alt="...">
                                      </div>
                                      <div class="carousel-item">
                                        <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734430/13_rsrteu.png" class="d-block w-100" alt="...">
                                      </div>
                                      <div class="carousel-item">
                                        <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734434/14_v8nhwp.png" class="d-block w-100" alt="...">
                                      </div>
                                      <div class="carousel-item">
                                        <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734461/15_euuvzd.png" class="d-block w-100" alt="...">
                                      </div>
                                      <div class="carousel-item">
                                        <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734439/16_kfeyzx.png" class="d-block w-100" alt="...">
                                      </div>
                                      <div class="carousel-item">
                                        <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734434/17_yq3ovg.png" class="d-block w-100" alt="...">
                                      </div>
                                      <div class="carousel-item">
                                        <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734435/18_p5stgg.png" class="d-block w-100" alt="...">
                                      </div>
                                      <div class="carousel-item">
                                        <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624734435/19_b9btrd.png" class="d-block w-100" alt="...">
                                      </div>

                                    </div>
                                    <button class="carousel-control-prev" type="button" data-bs-target="#project3" data-bs-slide="prev">
                                      <span class="carousel-control-prev-icon" aria-hidden="true" style="color:black"></span>
                                      <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#project3" data-bs-slide="next">
                                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                      <span class="visually-hidden">Next</span>
                                    </button>
                                  </div>
                                <h3>Snakeock</h3>
                                <ul class="post-meta list-inline">
                                    <li class="list-inline-item">
                                        <i class="fab fa-github trans"></i><span class="repo"><a href="https://github.com/aditya-5/snakeock" class="linkCol"> Repository</a></span>
                                    </li>
                                    <li class="list-inline-item">
                                        <i class="fa fa-tags trans"></i> Coursework
                                    </li><br>
                                    <li class="list-inline-item">
                                      <i class="fas fa-map-marker-alt"></i> University of Manchester
                                    </li>
                                    <br>
                                    <li class="list-inline-item">
                                      <i class="fas fa-code"></i> Python
                                    </li>

                                </ul>
                                <p>
                                  Snakeock (Snake v/s Block) game built using Python's TKinter GUI Library.
                                </p><br>
                            </div>
                        </div>
                    </div>






                    <div class="vtimeline-point">
                        <div class="vtimeline-icon">
                            <i class="fa fa-image"></i>
                        </div>
                        <div class="vtimeline-block">
                            <span class="vtimeline-date"> September, 2020</span><div class="vtimeline-content">

                                <div id="project10" class="carousel slide mb20" data-bs-ride="carousel">
                                    <div class="carousel-indicators">
                                      <button type="button" data-bs-target="#project10" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                      <button type="button" data-bs-target="#project10" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    </div>
                                    <div class="carousel-inner">
                                      <div class="carousel-item active">
                                        <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624794650/48_tzism1.png" class="d-block w-100" alt="...">
                                      </div>
                                      <div class="carousel-item">
                                        <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624794650/49_oqsae8.png" class="d-block w-100" alt="...">
                                      </div>
                                    </div>
                                    <button class="carousel-control-prev" type="button" data-bs-target="#project10" data-bs-slide="prev">
                                      <span class="carousel-control-prev-icon" aria-hidden="true" style="color:black"></span>
                                      <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#project10" data-bs-slide="next">
                                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                      <span class="visually-hidden">Next</span>
                                    </button>
                                  </div>
                                <h3>Calculator</h3>
                                <ul class="post-meta list-inline">
                                    <li class="list-inline-item">
                                        <i class="fab fa-github trans"></i><span class="repo"><a href="https://github.com/aditya-5/calculator-js" class="linkCol"> Repository</a></span>
                                    </li>
                                    <li class="list-inline-item">
                                        <i class="fa fa-tags trans"></i> Lab
                                    </li><br>
                                    <li class="list-inline-item">
                                      <i class="fas fa-map-marker-alt"></i> University of Manchester
                                    </li>
                                    <br>
                                    <li class="list-inline-item">
                                      <i class="fas fa-code"></i> HTML, CSS, BootStrap, JavaScript, jQuery
                                    </li>

                                </ul>
                                <p>
                                  A very simple front-end calculator built without any libraries.
                                </p><br>
                            </div>
                        </div>
                    </div>



                    <div class="vtimeline-point">
                        <div class="vtimeline-icon">
                            <i class="fa fa-image"></i>
                        </div>
                        <div class="vtimeline-block">
                            <span class="vtimeline-date"> May, 2020</span><div class="vtimeline-content">

                                <div id="project11" class="carousel slide mb20" data-bs-ride="carousel">
                                    <div class="carousel-indicators">
                                      <button type="button" data-bs-target="#project11" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                      <button type="button" data-bs-target="#project11" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                      <button type="button" data-bs-target="#project11" data-bs-slide-to="1" aria-label="Slide 2"></button>

                                    </div>
                                    <div class="carousel-inner">
                                      <div class="carousel-item active">
                                        <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624795050/50_whv8su.png" class="d-block w-100" alt="...">
                                      </div>
                                      <div class="carousel-item">
                                        <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624795050/51_mermci.png" class="d-block w-100" alt="...">
                                      </div>
                                      <div class="carousel-item">
                                        <img src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624795050/52_wjg4fj.png" class="d-block w-100" alt="...">
                                      </div>
                                    </div>
                                    <button class="carousel-control-prev" type="button" data-bs-target="#project11" data-bs-slide="prev">
                                      <span class="carousel-control-prev-icon" aria-hidden="true" style="color:black"></span>
                                      <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#project11" data-bs-slide="next">
                                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                      <span class="visually-hidden">Next</span>
                                    </button>
                                  </div>
                                <h3>To-Do App</h3>
                                <ul class="post-meta list-inline">
                                    <li class="list-inline-item">
                                        <i class="fab fa-github trans"></i><span class="repo"><a href="https://github.com/aditya-5/jquery-todo-list" class="linkCol"> Repository</a></span>
                                    </li>
                                    <li class="list-inline-item">
                                        <i class="fa fa-tags trans"></i> Online Course
                                    </li><br>
                                    <li class="list-inline-item">
                                      <i class="fas fa-map-marker-alt"></i> Web Development Bootcamp : Colt Steele
                                    </li>
                                    <br>
                                    <li class="list-inline-item">
                                      <i class="fas fa-code"></i> HTML, CSS, BootStrap, JavaScript, jQuery
                                    </li>

                                </ul>
                                <p>
                                  A non-persistent To-Do application.
                                </p><br>
                            </div>
                        </div>
                    </div>













    </div>
</div>
