  <div class="container">
    <div class="row mt-4 mb-4 justify-content-center">
    <div class="col-lg-2">
      <button type="button" class="form-control btn " [ngClass]="{'btn-danger':formalBool}" (click)="switchToFormal()">Formal Education</button>
    </div>
    <div class="col-lg-2">
      <button type="button" class="form-control btn " [ngClass]="{'btn-danger':informalBool}"(click)="switchToInformal()">Informal Education</button>
    </div>
</div>

  <div class="row" *ngIf="formalBool">
    <div class="col-12">
      <div class="card" >
        <div class="card-body">
          <div class="mt-5">
            <div class="timeline">


              <div class="timeline-wrapper timeline-wrapper-warning" >
                <div class="timeline-badge" (click)="toggleManc()"></div>
                <div class="timeline-panel" (click)="toggleManc()">
                  <div class="timeline-heading">

                    <div class="row">
                      <div class="col-10">
                    <h6 class="timeline-title">University of Manchester</h6>
                    <p class="place"> Manchester, England</p>
                    </div>
                    <div class="col-2">
                      <img style="height:40px;width:40px" src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1624882818/tap_vim4pi.gif" alt="">
                      </div>
                    </div>



                  </div>
                  <div class="timeline-body mb-4" *ngIf="MANC" @slideInOut>
                    <p>Computer Science with Industrial Experience</p>

                    <div class="d-flex  mb-3">
                      <button type="button" class="btn btn-sm sub-btn" [ngClass]="{'btn-danger':uomYears[0]}" onclick="event.stopPropagation();" (click)="toggleuomYears(0)">1st Year</button>
                      <button type="button" class="btn btn-sm sub-btn" [ngClass]="{'btn-danger':uomYears[1]}" onclick="event.stopPropagation();" (click)="toggleuomYears(1)">2nd Year</button>
                      <button type="button" class="btn btn-sm sub-btn" [ngClass]="{'btn-danger':uomYears[2]}" onclick="event.stopPropagation();" (click)="toggleuomYears(2)">3rd Year</button>
                      <button type="button" class="btn btn-sm sub-btn" [ngClass]="{'btn-danger':uomYears[3]}" onclick="event.stopPropagation();" (click)="toggleuomYears(3)">4th Year</button>
                  </div>
                  <div class="list-sm">
                    <div *ngIf="uomYears[0]">
                      <ul >
                        <li>COMP10120 First Year Web Dev Team Project </li>
                        <li>COMP11120 Mathematical Techniques for Computer Science</li>
                        <li>COMP11212 Fundamentals of Computation</li>
                        <li>COMP12111 Fundamentals of Computer Engineering</li>
                        <li>COMP13212 Data Science </li>
                        <li>COMP15111 Fundamentals of Computer Architecture</li>
                        <li>COMP15212 Operating Systems</li>
                        <li>COMP16321 Introduction to Programming 1 (Python)</li>
                        <li>COMP16412 Introduction to Programming 2 (Java)</li>
                      </ul>
                      <br>
                      Netcraft Prize : Awarded to the 10 students with the highest Year 1 overall mark
                      <img style="height:40px;width:40px" src="https://res.cloudinary.com/dbsuehmlk/image/upload/v1658648954/ribbon_g8lupo.png" alt="">
                    </div>
                    <div *ngIf="uomYears[1]">
                      <ul >
                        <li>COMP22111 Processor Microarchitecture</li>
                        <li>COMP23111 Database Systems</li>
                        <li>COMP23311 Software Engineering 1 & 2</li>
                        <li>COMP24011 Introduction to Artificial Intelligence</li>
                        <li>COMP26020 Programming Languages & Paradigms</li>
                        <li>COMP26120 Algorithms and Data Structures</li>
                        <li>COMP24112 Machine Learning</li>
                        <li>COMP28112 Distributed Systems</li>
                        <li>COMP27112 Visual Computing</li>
                      </ul>
                    </div>
                    <p *ngIf="uomYears[2]">Industrial Experience at MFT National Health Service (NHS), Manchester, UK </p>
                    <div *ngIf="uomYears[3]">
                      <ul>
                        <li>COMP30040 Agile Software Pipelines</li>
                        <li>COMP33312 User Experience</li>
                        <li>COMP33511 AI and Games</li>
                        <li>COMP34111 Cognitive Robotics</li>
                        <li>COMP34212 Natural Language Processing</li>
                        <li>COMP34711 Natural Language Understanding</li>
                        <li>COMP34812 Advanced Distributed Systems</li>
                        <li>COMP38311 Managing Finance in Enterprise</li>
                      </ul>
                    </div>

                  </div>
                  </div>
                  <div class="timeline-footer d-flex align-items-center flex-wrap">
                      <span class="ml-md-auto font-weight-bold">Graduated July 2024</span>
                  </div>
                </div>
              </div>



              <div class="timeline-wrapper timeline-inverted timeline-wrapper-danger">
                <div class="timeline-badge" (click)="toggleIIT()"></div>
                <div class="timeline-panel" (click)="toggleIIT()">
                  <div class="timeline-heading">
                    <h6 class="timeline-title">Indian Institute of Technology</h6>
                    <p class="place"> Kanpur, India</p>
                  </div>
                  <div class="timeline-body" *ngIf="IIT" @slideInOut>
                    <p>French Language Programme - Level 1</p>
                  </div>
                  <div class="timeline-footer d-flex align-items-center flex-wrap">
                      <span class="ml-md-auto font-weight-bold">August, 2019 - May, 2020</span>
                  </div>
                </div>
              </div>




              <div class="timeline-wrapper timeline-wrapper-success">
                <div class="timeline-badge" (click)="toggleDPS()"></div>
                <div class="timeline-panel" (click)="toggleDPS()">
                  <div class="timeline-heading">
                    <h6 class="timeline-title">Delhi Public School</h6>
                    <p class="place"> Kanpur, India</p>
                  </div>
                  <div class="timeline-body" *ngIf="DPS" @slideInOut>



                    <p><b>AISSCE (CBSE):</b> Physics, Mathematics, Chemistry, English, Web Technology, Physical Education, PHealth Education, General Studies and Work Experience</p>
                    <p><b>AISSE (CBSE): </b>English, Mathematics, Science, Social Science and Hindi</p>
                  </div>
                  <div class="timeline-footer d-flex align-items-center flex-wrap">
                      <span class="ml-md-auto font-weight-bold">April, 2007 - May, 2019</span>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- ***** -->

<div class="row" *ngIf="informalBool">
  <div class="col-12">
    <div class="card" >
      <div class="card-body">
        <div class="mt-5">
          <div class="timeline">


            <div *ngFor="let item of informalItems; let i = index" [ngClass]="item.wrapperClass">
              <div class="timeline-badge" (click)="toggleInformal(item.toggleIndex)"></div>
              <div class="timeline-panel" (click)="toggleInformal(item.toggleIndex)">
                <div class="timeline-heading">
                  <h6 class="timeline-title">{{ item.title }}</h6>
                  <p class="place"> <b>{{ item.place }}</b> : {{ item.instructors }}</p>
                </div>
                <div class="timeline-body" *ngIf="informalList[item.toggleIndex]" @slideInOut>
                  <!-- <p>{{ item.body }}</p> -->
                  <div [innerHTML]="item.body"></div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>



</div>
