
<nav class="navbar navbar-light  bg-light">
  <div class="container d-flex justify-content-center-custom">
    <ul class='nav middle-padding'>
      <li class="trans"><a class="navbar-brand" routerLink="/home">Aditya Agarwal</a></li><span class="brandtitle"></span>
      <li class="trans"><a href="https://www.linkedin.com/in/aditya-5/" class="linkCol"><i class="fab fa-linkedin inline" ></i></a></li>
      <li class="trans"><a href="https://github.com/aditya-5" class="linkCol"><i class="fab fa-github inline" ></i></a></li>
      <!-- <li class="trans"><a href="https://www.instagram.com/imaa45" class="linkCol"><i class="fab fa-instagram inline" ></i></a></li>
      <li class="trans"><a href="https://www.facebook.com/Aditya.Agarwalll/" class="linkCol"><i class="fab fa-facebook inline" ></i></a></li>
      <li class="trans"><a href="https://twitter.com/ImAa45" class="linkCol"><i class="fab fa-twitter inline" ></i></a></li> -->
      <li class="trans"><a href="mailto:aditya.7@outlook.com" class="linkCol"><i class="fas fa-at inline" ></i></a></li>
    </ul>


    <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown"> -->

      <ul class="nav">
        <li class="nav-item" routerLinkActive = "active" [routerLinkActiveOptions] = "{exact: true}">
          <a class="nav-link linkCol"  routerLink="/home">Homepage</a>
        </li>
        <li class="nav-item" routerLinkActive = "active" [routerLinkActiveOptions] = "{exact: true}">
          <a class="nav-link linkCol" routerLink="/projects">Projects</a>
        </li>
        <li class="nav-item" style="margin:0px" routerLinkActive = "active" [routerLinkActiveOptions] = "{exact: true}">
          <a class="nav-link linkCol" routerLink="/contact">Contact</a>
        </li>
        <li class="nav-item" routerLinkActive = "active" [routerLinkActiveOptions] = "{exact: true}">
          <a class="nav-link linkCol" routerLink="/education">Education</a>
        </li>
        <li class="nav-item" routerLinkActive = "active" [routerLinkActiveOptions] = "{exact: true}">
          <a class="nav-link linkCol" routerLink="/experience">Experience</a>
        </li>
      </ul>

    </div>
<!--
  </div> -->
</nav>
