<section class="mb-5" id="resume">
  <div class="container">
  
      <div class="row mt-4 mb-4 d-flex justify-content-center">
        <div class="col-lg-2">
          <button type="button" class="form-control btn " [ngClass]="{'btn-danger':experienceType[1]}"(click)="changeExpType(1)">Work Experience</button>
        </div>
        <div class="col-lg-2">
          <button type="button" class="form-control btn " [ngClass]="{'btn-danger':experienceType[0]}" (click)="changeExpType(0)">Extracurricular</button>
        </div>
      </div>
      <div class="col-lg-12 m-15px-tb" *ngIf="experienceType[0]">
        <div class="resume-box">
          <ul>

            
            <li *ngFor="let item of extraCurricularItems">
              <div class="icon">
                <i class="fas {{ item.iconClass }}"></i>
              </div>
              <div class="row">
                <div class="col-lg-10">
                  <span class="time">{{ item.time }}</span>
                  <h5>{{ item.title }}</h5>
                  <p>{{ item.description }}</p>
                </div>
                <div class="col-lg-2 d-flex justify-content-center align-self-center">
                  <img [class]="item.imgClass" [src]="item.imgUrl" alt="">
                </div>
              </div>
            </li>

          </ul>
        </div>
      </div>

<!-- ************************************************************
************************************************************ -->

      <div class="col-lg-12 m-15px-tb" *ngIf="experienceType[1]">
        <div class="resume-box">
          <ul>

            <li *ngFor="let item of workItems">
              <div class="icon">
                <i class="fas {{ item.iconClass }}"></i>
              </div>
              <div class="row">
                <div class="col-lg-10">
                  <span class="time">{{ item.time }}</span>
                  <h5>{{ item.title }}</h5>
                  <p>{{ item.description }}</p>
                </div>
                <div class="col-lg-2 d-flex justify-content-center align-self-center">
                  <img [class]="item.imgClass" [src]="item.imgUrl" alt="">
                </div>
              </div>
            </li>


          </ul>
        </div>
      </div>

    </div>
</section>
